@tailwind components;

@layer components {

    .vocabulary-search {
        @apply w-full lg:w-[220px] border border-purple rounded-[15px] py-[2px] pl-[33px] focus-visible:outline-0 focus-visible:outline-0 focus-visible:outline-none focus-visible:border-purple  focus-visible:ring-0 focus-visible:ring-transparent focus:border-purple
    }

    .table {
        @apply w-full h-full border-b border-dark-gray overflow-x-scroll;
    }

    .table thead tr th {
        @apply bg-light-grey-3 h-[52px]  text-left px-4 text-font text-md font-semibold;
    }

    .table thead th:first-child {
        @apply rounded-l-[8px];
    }

    .table thead th:last-child {
        @apply rounded-r-[8px];
    }

    .table thead th:not(th:last-child) {
        @apply border-r border-dark-gray;
    }
    .table tbody tr {
        @apply  h-[34px];
    }

    .table tbody td {
        @apply  px-4 max-w-[330px] text-ellipsis overflow-ellipsis whitespace-nowrap overflow-hidden ;
    }
    .table tbody td:not(td:last-child) {
        @apply border-r border-dark-gray;
    }
    .table tbody tr:nth-child(even) {
        @apply bg-light-purple ;
    }
}