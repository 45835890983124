@import "tailwindcss/base";

@import "tailwindcss/components";
@import "assets/styles/pages/profile.css";
@import "assets/styles/pages/learn.css";
@import "assets/styles/pages/auth.css";
@import "assets/styles/pages/lesson.css";
@import "assets/styles/pages/practice.css";
@import "assets/styles/pages/vocabulary.css";

@import "assets/styles/components/sidebar.css";
@import "assets/styles/components/spinner.css";

@import "tailwindcss/utilities";

@import "flowbite";

@font-face {
    font-family: "Montserrat";
    src: url("../src/assets/fonts/Montserrat-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("../src/assets/fonts/Montserrat-Regular.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("../src/assets/fonts/Montserrat-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url("../src/assets/fonts/Montserrat-Italic.ttf");
    font-style: italic;
}

@layer utilities {
    .backface-visible{
        backface-visibility: visible;
    }
    .backface-hidden{
        backface-visibility: hidden;
    }
    .transform-3d{
        transform-style: preserve-3d;
    }
    .perspective-10{
        perspective: 1000px;
    }
    .rotate-y-180{
        transform: rotateY(180deg);
    }
}

@layer base {
    html {
        @apply h-full;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        @apply h-full;
    }

    div#root {
        @apply h-full;
    }

    main {
        @apply h-full;
    }

    h4 {
        @apply text-xl font-black text-font lg:text-2xl;
    }

    h1 {
        @apply text-2xl text-font font-bold;
    }

    /*@media screen(lg) {*/
    /*    body {*/
    /*        @apply landscape:h-full;*/
    /*    }*/
    /*}*/


}


@layer components {

    .btn-purple {
        @apply bg-purple hover:opacity-90 text-bg-light-grey text-lg font-bold  h-[60px] rounded-[10px] lg:text-xl;
    }

    .btn-red {
        @apply btn-purple bg-red;
    }

    .btn-back {
        @apply bg-white hover:opacity-90 text-purple text-lg font-bold  h-[60px] rounded-[10px] border-2 border-purple lg:text-xl;
    }
    .btn-success {
        @apply bg-teal-dark hover:opacity-90 text-white text-base font-bold  h-[60px] rounded-[10px]  lg:text-base;
    }
    .link{
        @apply mt-2 font-bold text-xs text-purple decoration-purple decoration-solid underline flex;
    }

    .main-container {
        @apply max-w-[1072px] ;
    }

    .main-content {
        @apply flex  w-full;
    }

    button:disabled {
        opacity: .5 !important;
        cursor: not-allowed;
        pointer-events: none;
    }

}