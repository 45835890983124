@tailwind components;

@layer components {

    .field-profile .input {
        @apply mt-[5px] w-full h-[36px] border-purple box-border pl-[9px] leading-[29px] text-base text-font rounded-[10px] disabled:bg-light-grey disabled:outline-none disabled:border-none focus:outline-0 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent	focus:border-purple focus:border-2;
    }

    .field-profile .input-error {
        @apply border-red;
    }

    .field-profile .error {
        @apply absolute left-0 top-[72px] leading-[1]  text-xs text-red font-extralight;
    }

    .field-profile .label {
        @apply font-bold leading-[29px];
    }

    .hide-on-mobile {
        @apply hidden lg:block
    }
}