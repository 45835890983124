@tailwind components;

@layer components {

    .word{
        @apply bg-purple cursor-pointer hover:opacity-90 font-bold rounded-[10px] lg:rounded-[15px] self-center place-self-center flex items-center justify-center text-base lg:text-2xl text-white;
    }

    .sentence-word {
        @apply word relative lg:py-1 text-sm lg:text-2xl p-[6px] lg:px-[10px] mr-[2px] lg:mr-2 last-of-type:mr-0 mt-[5px] lg:mt-5;
    }

    .result-line {
       @apply w-full lg:w-[610px] py-0 text-center border-x-0 border-t-0 border-b text-base lg:text-xl text-font border-dark-gray focus:ring-0 focus:border-dark-gray;
    }
    .words-input {
        @apply w-full lg:w-auto;
    }
    .words-input[type='text']{
        @apply result-line;
    }

    .radio-wrap {
        @apply relative mr-2 flex ;
    }
    .radio-button[type='radio']:checked{
        /*box-shadow: 0 0 0 1px rgb(87, 92, 164);*/
        @apply bg-white bg-none border border-purple;
    }
    .check-mark {

    }

    .radio-button[type='radio']:checked + .check-mark {
        @apply block absolute left-1/2 bg-purple w-3 h-3 rounded-[50%] top-1/2 -translate-x-1/2 -translate-y-1/2
    }

    .radio-button[type='radio']{
       @apply focus:ring-inset focus:ring-offset-0  focus:ring-transparent
    }
}