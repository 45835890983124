@tailwind components;

@layer components {

    .field-auth, .field-profile{
        @apply  relative;
    }

    .field-auth .error{
        @apply absolute left-0 top-[58px] leading-[1] lg:top-[54px]  text-xs text-red font-extralight;
    }

    .field-auth .input{
        @apply rounded shadow-base pt-[13px] pb-[11px] px-4 text-font text-base font-light  w-full border-0 placeholder:text-gray focus:outline-0;
    }
}