@tailwind components;

@layer components {


    .light-lesson {
        @apply text-font bg-white;
    }

    .dark-lesson {
        @apply text-white bg-teal-dark lg:bg-lesson-card;
    }
}