@tailwind components;

.StripeElement {
    box-sizing: border-box;
    background: white;
    border: 1px solid #575ca4;
    padding: 9px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
}
.StripeElement--focus {
    border: 1px solid #575ca4;
    box-shadow: 0 0 0 1px #575ca4;
}

.StripeElement--invalid {
    /*border-color: #ef4f6c;*/
    color: #383737;
    border: 1px solid #575ca4;
}
.is-invalid {
    color: #383737;
}

.error-input-msg {
    margin-top: 5px;
    font-weight: 500;
    color: #ef4f6c;
    text-align: left;
    font-size: 14px;
}

@layer components {

    .stripe-input-wrapper .input {
        @apply w-full h-[37px] border-[1px] border-solid border-purple box-border p-[10px] text-base text-font rounded-[8px] disabled:bg-light-grey disabled:outline-none disabled:border-none focus:outline-0 focus:ring-0 focus:ring-transparent focus:ring-offset-transparent	focus:border-purple focus:border-2;
    }

    .stripe-input-wrapper .input-error {
        @apply border-red;
    }

    .stripe-input-wrapper .error {
        @apply absolute left-0 top-[73px] leading-[1] lg:top-[70px]  text-xs text-red font-extralight;
    }

    .stripe-input-wrapper .label {
        @apply font-bold leading-[29px];
    }
}