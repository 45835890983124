@tailwind components;

@layer components {


    .lesson-content {
        @apply text-font text-md leading-[34px];
    }

    .lesson-content p {
        @apply text-font text-md  leading-[34px];
    }

    .lesson-content h2 {
        @apply text-light-black text-lg font-bold underline decoration-light-black decoration-solid mt-[30px] mb-5;
    }

    .lesson-content strong {
        @apply font-bold;
    }

    .lesson-content img {
        @apply w-full h-auto object-contain    my-10;
    }


    .lesson-marked-word {
        @apply relative border-b pb-[0.4rem] cursor-auto
    }

    .lesson-marked-word:before {
        content: "";
        border-right: 1px solid currentColor;
        line-height: inherit;
        width: 0;
        transform: rotate(-12deg) translate(-1px, 1px);
        position: absolute;
        height: 10px;
        transform-origin: bottom;
        top: 18px;
    }

    .lesson-marked-word:after {
        content: "";
        border-left: 1px solid currentColor;
        line-height: inherit;
        transform: rotate(12deg) translate(0px, 1px);
        position: absolute;
        height: 10px;
        transform-origin: bottom;
        bottom: .4px;
    }

    .lesson-marked-word-definition {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
        text-align: center;
    }

    .font-lg-pure .lesson-marked-word:before {
        top: 25px
    }

    .font-sm-pure .lesson-marked-word:before {
        top: 13px
    }

    .font-sm-pure {
        font-size: 0.875rem;
        line-height: 1.75rem;
    }

    .font-sm-pure p {
        font-size: 0.875rem;
        line-height: 1.75rem;
    }

    .font-sm-pure span {
        font-size: 0.875rem;
        line-height: 1.75rem;
    }

    .font-lg-pure {
        font-size: 1.5rem;
        line-height: 2.625rem;
    }

    .font-lg-pure p {
        font-size: 1.5rem;
        line-height: 2.625rem;
    }

    .font-lg-pure span {
        font-size: 1.5rem;
        line-height: 2.625rem;

    }
}