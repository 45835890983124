#scrollableDivCustom, #scrollableDiv {
  @media (min-width: 1024px) {
    width: calc(100% + 10px);
    margin-right: 6px;
    padding-right: 4px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(10,154,172,0.0);
    }
    &::-webkit-scrollbar-thumb{
      padding-top: 44px;
      border-radius: 10px;
      background: rgba(10,154,172,0.2);
    }
    &::-webkit-scrollbar-thumb:hover{
      background: rgba(10,154,172,0.4);
    }
    &::-webkit-scrollbar-thumb:active{
      background: rgba(10,154,172,.9);
    }
  }
}